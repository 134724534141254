@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    -webkit-tap-highlight-color: transparent;
    @apply antialiased;
}

html {
    @apply scroll-smooth motion-reduce:scroll-auto;
}

body {
    overflow-x: hidden;
}